export const BG_PRIMARY = 'rgba(230,225,207,1)';
export const BG_SECONDARY = 'rgba(70,57,45,1)';
export const BG_ALTERNATIVE = 'rgba(152,118,87,1)';
export const BG_CARD = '#FFFFFF'; // For RSVP, WEDDING GIFT, COUNTDOWN / LIVESTREAM Section
export const BG_OPACITY = 'rgba(70,57,45,1)';

export const TEXT_PRIMARY = 'rgba(0,0,0,1)';
export const TEXT_SECONDARY = 'rgba(255,255,255,1)';
export const TEXT_PRIMARY_DARK = 'rgba(70,57,45,1)';

export const NAVIGATION_COLOR = '#8c7867';
export const ICON_NAVIGATION_COLOR = '#FFFFFF';
