import { extendTheme } from '@chakra-ui/react';
import { FONT_FAMILY } from '@/constants/fonts';
import {
  BG_SECONDARY,
  BG_PRIMARY,
  BG_ALTERNATIVE,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_PRIMARY_DARK,
  BG_OPACITY,
  BG_CARD,
} from '@/constants/colors';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://chakra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: FONT_FAMILY.heading,
    body: FONT_FAMILY.body,
    fantasy: FONT_FAMILY.fantasy,
    jost: `'Jost', serif`,
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgSecondary: BG_SECONDARY,
    bgAlternative: BG_ALTERNATIVE,
    bgCard: BG_CARD,
    bgOpacity: BG_OPACITY,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    mainColorTextDark: TEXT_PRIMARY_DARK,
    transparent: 'transparent',
    theme: {
      bgPrimaryText: TEXT_PRIMARY,
      bgPrimary: BG_PRIMARY,
      bgAlternative: BG_ALTERNATIVE,
    },
    button: {
      secondary: {
        500: BG_SECONDARY,
      },
      secondaryDark: {
        500: BG_SECONDARY,
      },
    },
  },
  shadows: {
    outline: BG_SECONDARY,
  },
};

export default extendTheme(theme);
